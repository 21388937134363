
import { defineComponent } from "vue-demi";
import questions from "@/locales/ru/questions.json";
import Faq from './Faq.vue';

export default defineComponent({
  name: "PartnerFaq",
  data() {
    return {
      questions,
    };
  },
  components: {
    Faq
  }
});
