
import { defineComponent } from "vue-demi";
import { PlusIcon, MinusIcon } from '@/plugins/icons';

export default defineComponent({
  name: 'Faq',
  props: {
    faq: Object
  },
  data() {
    return {
      menu: false,
    }
  },
  components: {
    PlusIcon,
    MinusIcon
  }
})
