import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4400a378"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partner flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partner_program = _resolveComponent("partner-program")!
  const _component_partner_calculator = _resolveComponent("partner-calculator")!
  const _component_partner_steps = _resolveComponent("partner-steps")!
  const _component_main_leader = _resolveComponent("main-leader")!
  const _component_partner_education = _resolveComponent("partner-education")!
  const _component_partner_faq = _resolveComponent("partner-faq")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_partner_program),
    _createVNode(_component_partner_calculator),
    _createVNode(_component_partner_steps),
    _createVNode(_component_main_leader),
    _createVNode(_component_partner_education),
    _createVNode(_component_partner_faq)
  ]))
}