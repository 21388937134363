
import { defineComponent } from "vue-demi";
import Range from './Range.vue';

export default defineComponent({
  props: ['empty'],
  name: "PartnerCalculator",
  data() {
    return {
      amount: 100,
      ranges: [
        { min: 1, max: 500, step: 1, value: 1, title: 'Кол-во клиентов', mask: '####' },
        { min: 1000, max: 1000000, step: 50, value: 1000, title: 'Сумма заказа', mask: '########' },
      ]
    };
  },
  computed: {
    totalAmount() {
      return Math.ceil((this.ranges[0].value * this.ranges[1].value) / 10);
    }
  },
  components: {
    Range
  }
});
