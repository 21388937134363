
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { ROLES } from "@/utils/enums";
import { User } from "@/interfaces/user/user.dto";

export default defineComponent({
  name: 'ProfileEducationMain',
  methods: {
    partnerAction() {
      if (this.user) {
        this.$router.push('/profile');
      } else {
        this.$router.push('/registration?partner=partner');
      }
    },
    partnerTest() {
      this.$router.push('/partner/test');
    },
  },
  computed: {
    ...mapGetters(['user']),
    isShow() {
      const user = this.user as User.Dto;
      return !user || user?.role === ROLES.USER;
    }
  }
})
