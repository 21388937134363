
import { defineComponent } from "vue-demi";
import MainLeader from '../main/modules/leader/index.vue';
import PartnerCalculator from './modules/calculator/index.vue';
import PartnerProgram from './modules/program/index.vue';
import PartnerFaq from './modules/faq/index.vue';
import PartnerSteps from './modules/steps/index.vue';
import PartnerEducation from './modules/education/index.vue';

export default defineComponent({
  name: 'MainPartnerMain',
  components: {
    MainLeader,
    PartnerCalculator,
    PartnerProgram,
    PartnerFaq,
    PartnerSteps,
    PartnerEducation
  }
})
