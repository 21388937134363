import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12e69592"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "faq fz20" }
const _hoisted_2 = {
  key: 0,
  class: "description als"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "title cursor alsb flex-center-between",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menu = !_ctx.menu))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.faq.title), 1),
      (!_ctx.menu)
        ? (_openBlock(), _createBlock(_component_plus_icon, {
            key: 0,
            color: "#000"
          }))
        : (_openBlock(), _createBlock(_component_minus_icon, {
            key: 1,
            color: "#000"
          }))
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.menu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.faq.description), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}