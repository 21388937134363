import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3500ec21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partner-faq" }
const _hoisted_2 = { class: "title common--title leksa_title_50" }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faq = _resolveComponent("faq")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.questions.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions.list, (faq, index) => {
        return (_openBlock(), _createBlock(_component_faq, {
          key: index,
          faq: faq
        }, null, 8, ["faq"]))
      }), 128))
    ])
  ]))
}