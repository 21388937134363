
import { defineComponent } from "vue-demi";
import { mask } from 'vue-the-mask';

export default defineComponent({
  directives: {
    mask
  },
  name: "CalculatorRange",
  props: ["range"],
});
