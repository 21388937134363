
import { defineComponent } from "vue-demi";
import PartnerMain from "@/components/main/partner/index.vue";

export default defineComponent({
  name: "PartnerView",
  components: {
    PartnerMain,
  },
});
