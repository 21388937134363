import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40e66090"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element" }
const _hoisted_2 = { class: "title als fz16 flex-center-between" }
const _hoisted_3 = ["min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.range.title), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.range.value) = $event))
      }, null, 512), [
        [_vModelText, _ctx.range.value],
        [_directive_mask, _ctx.range.mask]
      ])
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "range",
      ref: "input",
      min: _ctx.range.min,
      max: _ctx.range.max,
      step: _ctx.range.step,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.range.value) = $event))
    }, null, 8, _hoisted_3), [
      [_vModelText, _ctx.range.value]
    ])
  ]))
}